import { useState, useCallback, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import Modal from "components/modal/Modal";
import { Button } from "components/ui/button/Button";
import {
  useDropzone,
  FileRejection,
  FileError,
  ErrorCode,
  Accept,
} from "react-dropzone";
import "./file-uploader.scss";
import useDesktopChecker from "hooks/useDesktopChecker";
import DocumentUpload from "components/document-upload/DocumentUpload";
import {
  useUploadDocumentsWithSpecificConditionMutation,
  useUploadDocumentsApplicationMutation,
  useDeleteDocumentMutation,
  useDeleteDocumentForDealApplicationMutation,
  useUploadDocumentsForDealApplicationMutation,
  useUploadDocumentsWithSpecificConditionForDealApplicationMutation,
} from "pages/dashboard/home/dashboardService";
import {
  getCurrentUserApplicationId,
  getDealApplicationId,
  getUserId,
} from "features/authSlice";
import { useSelector } from "react-redux";
import {
  AvailableFileTypes,
  DASHBOARD_MAXFILES_LIMIT_REACHED_ERROR,
  DefaultAcceptedFileType,
  DefaultAllowedExtension,
  DOCUMENT_MAX_SIZE_IN_BYTES,
} from "utilities/Constant";
import {
  FeatureFlag,
  useGetFeatureFlagsQuery,
} from "services/common/featureFlagService";
import { GetFileExtension } from "utilities/file";

export enum uploadProgressStatus {
  "yetToStart",
  "ReadyToUpload",
  "uploading",
  "uploaded",
}

export interface UploadableFile {
  file: File;
  errors: FileError;
  uploadProgress: "" | uploadProgressStatus;
  documentId?: string;
}

export interface FileUploaderProps {
  handleByAction?: () => void;
  onClick?: () => void;
  conditionId?: string;
  operationType:
  | "UploadDocumentsWithSpecificCondition"
  | "UploadDocuments"
  | "";
}

export default function FileUploader({
  handleByAction,
  onClick,
  conditionId,
  operationType,
}: FileUploaderProps) {
  const [fileCount, setFileCount] = useState(0);

  const getFileCount = (count: number) => {
    setFileCount(count);
  };
  return (
    <div className="file_uploader-modal">
      <Modal
        defaultHeader
        defaultHandleHide={onClick}
        shouldShowCloseButton={fileCount <= 0}
        defaultHeaderName="Upload Files"
        defaultHeaderIcon={solid("cloud-arrow-up")}
        defaultBody={false}
        customBody={
          <DragAndDrop
            handleByAction={handleByAction}
            conditionId={conditionId}
            operationType={operationType}
            onClick={onClick}
            onFileCountChange={getFileCount}
          />
        }
      />
    </div>
  );
}

interface DragAndDropProps {
  conditionId?: string;
  operationType:
  | "UploadDocumentsWithSpecificCondition"
  | "UploadDocuments"
  | "";
  onClick?: () => void;
  handleByAction?: () => void;
  onFileCountChange?: (count: number) => void;
}

export const GetAcceptableFileTypes = (allowedUploadFileTypes: string): Accept => {

  if (!allowedUploadFileTypes) return DefaultAcceptedFileType;

  const allowedTypesArray: string[] = allowedUploadFileTypes.split(',').map(type => type.trim().toLowerCase());

  const filteredFileTypes: Accept = Object.fromEntries(
    Object.entries(AvailableFileTypes).filter(([, extensions]: [string, string[]]) =>
      extensions.some((ext: string) => allowedTypesArray.includes(ext.substring(1)))
    )
  );

  return filteredFileTypes;
};

export const GetAcceptableFileExtensions = (allowedUploadFileTypes: string | undefined): Set<string> => {
  let extensions: string[] = [];

  if (allowedUploadFileTypes) {
    extensions = allowedUploadFileTypes.split(",").map(t => t.replace(".", "").toLowerCase());
  }
  else {
    extensions = [DefaultAllowedExtension];
  }

  return new Set(extensions);
};

export const GetAcceptableFileExtensionsString = (allowedUploadFileTypes: string | undefined): string => Array.from(GetAcceptableFileExtensions(allowedUploadFileTypes)).join(", ");

const DragAndDrop = ({
  conditionId,
  operationType,
  handleByAction,
  onFileCountChange,
}: DragAndDropProps) => {
  const isDesktop = useDesktopChecker();
  const userId = useSelector(getUserId);

  const [FileUploadDocumentsWithSpecificCondition] =
    useUploadDocumentsWithSpecificConditionMutation();
  const [FileUploadDocumentsWithSpecificConditionForDealApplicationMutation] =
    useUploadDocumentsWithSpecificConditionForDealApplicationMutation();
  const [FileUploadWithApplication] = useUploadDocumentsApplicationMutation();
  const [FileUploadForDealApplication] =
    useUploadDocumentsForDealApplicationMutation();
  const [DeleteDocument] = useDeleteDocumentMutation();
  const [DeleteDocumentForDealApplication] =
    useDeleteDocumentForDealApplicationMutation();
  const { data: featureFlag } = useGetFeatureFlagsQuery();

  const [isClick, setIsClick] = useState(false);
  const [files, setFiles] = useState<UploadableFile[]>([]);
  const [rejectedFiles, setRejectedFiles] = useState<UploadableFile[]>([]);
  const uploadableFileLimit = 12;
  const totalFileCount = useRef(files.length);
  totalFileCount.current = files.length;
  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      const totalAcceptedFiles =
        totalFileCount.current + acceptedFiles.length + fileRejections.length;
      const excessFilesCount =
        totalAcceptedFiles - uploadableFileLimit < 0
          ? 0
          : totalAcceptedFiles - uploadableFileLimit;
      const excessRejFileCount = Math.min(
        fileRejections.length,
        excessFilesCount
      );
      fileRejections = fileRejections.slice(
        0,
        fileRejections.length - excessRejFileCount
      );
      const ExcessAccFileCount =
        excessFilesCount - excessRejFileCount < 0
          ? 0
          : excessFilesCount - excessRejFileCount;
      acceptedFiles = acceptedFiles.slice(
        0,
        acceptedFiles.length - ExcessAccFileCount
      );
      const mappedAcceptedFile = acceptedFiles.map<UploadableFile>((file) => ({
        ...file,
        file: file,
        errors: {
          code: "",
          message: "",
        },
        documentId: "",
        uploadProgress: uploadProgressStatus.yetToStart,
      }));
      const mappedRejectedFile = fileRejections.map<UploadableFile>(
        (rejfiles) => ({
          ...rejfiles,
          file: rejfiles.file,
          errors: {
            code: rejfiles.errors[0].code,
            message: rejfiles.errors[0].message,
          },
          documentId: "",
          uploadProgress: "",
        })
      );
      setFiles((Current) => {
        return [...Current, ...mappedAcceptedFile];
      });
      setRejectedFiles((current) => {
        return [...current, ...mappedRejectedFile];
      });
    },
    []
  );
  const [filesCountInfo, setFilesCountInfo] = useState({
    uploadedCount: 0,
    totalFileCount: 0,
  });
  const uploadedCount = files.filter(
    (obj) =>
      obj.uploadProgress === uploadProgressStatus.uploading ||
      obj.uploadProgress === uploadProgressStatus.uploaded
  ).length;

  useEffect(() => {
    const fileCount = files.length + rejectedFiles.length;
    if (onFileCountChange) {
      onFileCountChange(fileCount);
    }
    const uploadedCount = files.filter(
      (obj) =>
        obj.uploadProgress === uploadProgressStatus.uploading ||
        obj.uploadProgress === uploadProgressStatus.uploaded
    ).length;
    setFilesCountInfo({ uploadedCount, totalFileCount: fileCount });
  }, [files, rejectedFiles]);

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    accept: GetAcceptableFileTypes(featureFlag?.allowedUploadFileTypes ?? ""),
    maxSize: DOCUMENT_MAX_SIZE_IN_BYTES,
    noClick: true,
    noKeyboard: true,
  });

  const applicationId = useSelector(getCurrentUserApplicationId);
  const dealApplicationId = useSelector(getDealApplicationId);

  const onDelete = async (file: UploadableFile) => {
    try {
      const formData = new FormData();
      conditionId && formData.append("conditionId", conditionId);
      formData.append("documentId", file.documentId ?? "");

      if (applicationId) {
        applicationId && formData.append("applicationId", applicationId);
        await DeleteDocument(formData);
      } else if (dealApplicationId) {
        dealApplicationId &&
          formData.append("dealApplicationId", dealApplicationId);
        await DeleteDocumentForDealApplication(formData);
      }

      setFiles((current) => current.filter((r) => r.file !== file.file));
      setRejectedFiles((current) =>
        current.filter((r) => r.file !== file.file)
      );
    } catch (error) {
      console.error("Error deleting document:", error);
    }
  };

  const isValidFileType = (file: UploadableFile): boolean => {
    const okExtensions = GetAcceptableFileExtensions(featureFlag?.allowedUploadFileTypes);
    // Check for valid file extension. If not found, add file error and return.
    if (!new Set(okExtensions).has(GetFileExtension(file.file).toLowerCase())) {
      setFiles((prev) =>
        prev.map((item) =>
          item.file === file.file
            ? {
              ...item,
              errors: {
                code: ErrorCode.FileInvalidType,
                message: "File type invalid"
              }
            }
            : item
        )
      );

      return false;
    }
    return true;
  };

  const fileUploadDocumentsWithSpecificCondition = async (
    file: UploadableFile
  ) => {
    try {
      if (!isValidFileType(file)) {
        return;
      }
      const formData = new FormData();
      formData.append("operation", operationType);
      formData.append("conditionId", conditionId ?? "");
      formData.append("Files.Content", file.file);
      let response = null;
      if (applicationId) {
        formData.append("applicationId", applicationId);
        formData.append("borrowerId", userId);
        response = await FileUploadDocumentsWithSpecificCondition(formData);
      }
      else {
        formData.append("dealApplicationId", dealApplicationId);
        formData.append("borrowerId", userId);
        response = await FileUploadDocumentsWithSpecificConditionForDealApplicationMutation(formData);
      }
      if ("data" in response && typeof response.data === "string") {
        const documentId: string = response.data;
        setFiles((prev) =>
          prev.map((item) =>
            item.file === file.file
              ? {
                ...item,
                uploadProgress: uploadProgressStatus.uploaded,
                documentId,
              }
              : item
          )
        );
      } else {
        console.error("Invalid response format:", response);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const UploadDocuments = async (file: UploadableFile) => {
    try {
      if (!isValidFileType(file)) {
        return;
      }
      const formData = new FormData();
      formData.append("Files.Content", file.file);

      let response = null;

      if (applicationId) {
        applicationId && formData.append("applicationId", applicationId);
        response = await FileUploadWithApplication(formData);
      } else if (dealApplicationId) {
        dealApplicationId &&
          formData.append("dealApplicationId", dealApplicationId);
        response = await FileUploadForDealApplication(formData);
      }

      if (response && "data" in response && typeof response.data === "string") {
        const documentId: string = response.data;
        setFiles((prev) =>
          prev.map((item) =>
            item.file === file.file
              ? {
                ...item,
                uploadProgress: uploadProgressStatus.uploaded,
                documentId,
              }
              : item
          )
        );
      } else {
        console.error("Invalid response format:", response);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const checkIsFilesUploaded: boolean =
    files.every(
      (obj) =>
        obj.uploadProgress === uploadProgressStatus.uploaded &&
        obj.errors.code === ""
    ) && files.length > 0;
  const changeUploadProgressStatus = (
    file: UploadableFile,
    uploadProgressStatus: uploadProgressStatus
  ) => {
    setFiles((prev) =>
      prev.map((item) =>
        item.file === file.file
          ? { ...item, uploadProgress: uploadProgressStatus }
          : item
      )
    );
  };

  useEffect(() => {
    const uploadFiles = async () => {
      const fileteredYetToStartFiles = files.filter(
        (x) => x.uploadProgress == uploadProgressStatus.yetToStart
      );
      for (const file of fileteredYetToStartFiles) {
        changeUploadProgressStatus(file, uploadProgressStatus.ReadyToUpload);
      }
      for (const file of fileteredYetToStartFiles) {
        changeUploadProgressStatus(file, uploadProgressStatus.uploading);
        if (operationType === "UploadDocumentsWithSpecificCondition") {
          await fileUploadDocumentsWithSpecificCondition(file);
        } else if (operationType === "UploadDocuments") {
          await UploadDocuments(file);
        }
      }
    };
    if (files.length !== 0) {
      void (async () => {
        await uploadFiles();
      })();
    }
  }, [files.length]);

  const isFileLimitReached = uploadedCount >= uploadableFileLimit;

  const doumentsMapping = (
    FileList: UploadableFile[],
    onDelete: (file: UploadableFile) => Promise<void>
  ) => {
    return (
      <>
        {FileList.map((fileWrapper) => {
          return (
            <div
              key={`${fileWrapper.file.name}+${fileWrapper.file.lastModified}`}
            >
              <div>
                <DocumentUpload
                  uploadableFile={{
                    file: fileWrapper.file,
                    errors: {
                      code: fileWrapper.errors.code,
                      message: fileWrapper.errors.message,
                    },
                    uploadProgress: fileWrapper.uploadProgress,
                    documentId: fileWrapper.documentId,
                  }}
                  onDelete={onDelete}
                />
              </div>
            </div>
          );
        })}
      </>
    );
  };
  const handleTakePhotoInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const fileList = event.target.files;
    if (fileList && fileList.length > 0) {
      const capturedFile = fileList[0];
      if (capturedFile.size > DOCUMENT_MAX_SIZE_IN_BYTES) {
        const rejectedFile: UploadableFile = {
          ...capturedFile,
          file: capturedFile,
          errors: {
            code: ErrorCode.FileTooLarge,
            message: "",
          },
          documentId: "",
          uploadProgress: "",
        };
        setRejectedFiles((Current) => {
          return [...Current, rejectedFile];
        });
      } else {
        const acceptedFile: UploadableFile = {
          ...capturedFile,
          file: capturedFile,
          errors: {
            code: "",
            message: "",
          },
          documentId: "",
          uploadProgress: uploadProgressStatus.yetToStart,
        };
        setFiles((Current) => {
          return [...Current, acceptedFile];
        });
      }
    }
  };

  const handleClick = () => {
    handleByAction?.();
    setIsClick(true);
  };
  useEffect(() => {
    setIsClick(false);
  }, [checkIsFilesUploaded]);
  return (
    <div className="file_uploader-container">
      <div
        {...getRootProps()}
        className={isFileLimitReached ? "dropzone disabled" : "dropzone"}
      >
        <input {...getInputProps()} />
        {isDesktop ? (
          <DesktopUploader
            filesCountInfo={filesCountInfo}
            open={open}
            isFileLimitReached={isFileLimitReached}
            featureFlag={featureFlag}
          />
        ) : (
          <MobileUploader
            filesCountInfo={filesCountInfo}
            open={open}
            handleFileInputChange={handleTakePhotoInputChange}
            isFileLimitReached={isFileLimitReached}
            featureFlag={featureFlag}
          />
        )}
      </div>
      {uploadedCount >= 12 && (
        <p className="maxfile-error">
          {DASHBOARD_MAXFILES_LIMIT_REACHED_ERROR}
        </p>
      )}
      <div className="document_uploader-container">
        {doumentsMapping(rejectedFiles, onDelete)}
        {doumentsMapping(files, onDelete)}
      </div>
      <div className="footer-btn">
        <Button
          className="footer-btn__upload"
          title="Confirm & Upload"
          type="round"
          variant="primary"
          disabled={!checkIsFilesUploaded || isClick}
          onClick={handleClick}
        />
      </div>
    </div>
  );
};

interface DesktopUploaderProps {
  open: () => void;
  filesCountInfo: { totalFileCount: number; uploadedCount: number };
  isFileLimitReached: boolean;
  featureFlag: FeatureFlag | undefined;
}

const DesktopUploader = ({
  open,
  filesCountInfo,
  isFileLimitReached,
  featureFlag,
}: DesktopUploaderProps) => {
  return (
    <>
      <FontAwesomeIcon icon={regular("cloud-arrow-up")} className="icon" />
      <p className="p1">
        DROP FILES HERE <br /> OR
      </p>
      <div>
        <Button
          title="Attach Files"
          type="round"
          variant="primary"
          onClick={open}
          disabled={isFileLimitReached}
        />
      </div>
      <div className="number">
        Maximum of 12 files can be attached per upload &nbsp;
        {filesCountInfo.totalFileCount > 0 && (
          <>
            <p className="number">|&nbsp;</p>
            <p className="number__count">
              {filesCountInfo.uploadedCount} of {filesCountInfo.totalFileCount}{" "}
              files have been uploaded
            </p>
          </>
        )}
      </div>
      <div className="files">
        <div className="size">
          <p className="size__p3">
            <b>Max size per file:</b>
          </p>
          <p className="size__p4">20MB</p>
        </div>
        <p className="p2">
          <b>Acceptable files:</b>
        </p>
        <p className="p5">
          {(
            GetAcceptableFileExtensionsString(featureFlag?.allowedUploadFileTypes)
          )}
        </p>
      </div>
    </>
  );
};

interface MobileUploaderProps {
  open: () => void;
  filesCountInfo: { totalFileCount: number; uploadedCount: number };
  handleFileInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isFileLimitReached: boolean;
  featureFlag: FeatureFlag | undefined;
}

const MobileUploader = ({
  open,
  filesCountInfo,
  handleFileInputChange,
  isFileLimitReached,
  featureFlag,
}: MobileUploaderProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleTakePhotoClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  return (
    <>
      <FontAwesomeIcon icon={regular("cloud-arrow-up")} className="icon" />
      <input
        type="file"
        className="file-input__hidden"
        ref={fileInputRef}
        capture="environment"
        accept="image/*"
        onChange={handleFileInputChange}
      />
      <div className="icon-container">
        {featureFlag?.cameraEnabled && (
          <div
            className="outer-circle"
            onClick={!isFileLimitReached ? handleTakePhotoClick : undefined}
          >
            <div className="icon-container__icon1">
              <FontAwesomeIcon
                className="camera-icon"
                icon={regular("camera")}
              />
            </div>
            <p className="icon-container__text1">Take a Photo</p>
          </div>
        )}
        <div
          className="outer-circle"
          onClick={!isFileLimitReached ? open : undefined}
        >
          <div className="icon-container__icon2">
            <FontAwesomeIcon
              className="folder-open-icon"
              icon={regular("folder-open")}
            />
          </div>
          <p className="icon-container__text2">Attach Files</p>
        </div>
      </div>
      <div className="number">
        Maximum of 12 files can be attached per upload {""}
        {filesCountInfo.totalFileCount > 0 && (
          <p className="number__count">
            {filesCountInfo.uploadedCount} of {filesCountInfo.totalFileCount}{" "}
            files ready to be uploaded
          </p>
        )}
      </div>
      <div className="files">
        <p className="p2">
          <b>Acceptable files:</b>
        </p>
        <p className="p5">
          {(
            GetAcceptableFileExtensionsString(featureFlag?.allowedUploadFileTypes)
          )}
        </p>
      </div>
      <div className="size">
        <p className="size__p3">Max size per file:</p>
        <p className="size__p4">20MB</p>
      </div>
    </>
  );
};
